// @flow
import React from 'react';
import styles from './Content.module.scss';
import Search from '../../Search'

type Props = {
  body: string,
  title: string,
  readingTime: string
};

const searchIndices = [
  { name: process.env.GATSBY_ALGOLIA_INDEX_NAME, title: `Blog Posts`, hitComp: `PostHit` },
]

const Content = ({ body, title, readingTime }: Props) => (
  <div className={styles['content']}>
    <Search collapse={true} indices={searchIndices} />
    <h1 className={styles['content__title']}>{title}</h1>
    <p className={styles['content__readingTime']}>{readingTime}</p>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
