import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

import styles from "./ShareButtons.module.scss";

export const ShareButtons = ({ twitterHandle, url, title }) => (
  <div className={styles["share"]}>
    <h4 className={styles["share-title"]}>{"SHARE THIS POST"}</h4>
    <br />
    <div className={styles["share-buttons"]}>
      <FacebookShareButton
        url={url}
        className="button is-outlined is-rounded facebook"
      >
        <button className={styles["facebook"]}>Facebook</button>
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        className="button is-outlined is-rounded twitter"
        title={title}
        via={twitterHandle}
      >
        <button className={styles["twitter"]}>Twitter</button>
      </TwitterShareButton>
      <LinkedinShareButton
        url={url}
        className="button is-outlined is-rounded linkedin"
        title={title}
      >
        <button className={styles["linkedin"]}>LinkedIn</button>
      </LinkedinShareButton>
      <RedditShareButton
        url={url}
        className="button is-outlined is-rounded reddit"
        title={title}
      >
        <button className={styles["reddit"]}>Reddit</button>
      </RedditShareButton>
    </div>
  </div>
);

export default ShareButtons;
