// @flow
import React from "react";
import { getContactHref } from "../../../utils";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";
import EmailListForm from "../../EmailListForm";
import PostSuggestionForm from "../../PostSuggestionForm";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles["author"]}>
      {/* Disabled to make more aware of the subscribe form and also now I have commento with anonymous feature <PostSuggestionForm /> */}
      <EmailListForm />
    </div>
  );
};

export default Author;
