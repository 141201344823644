import React, { useState, Component } from 'react';
import * as styles from './PostSuggestionForm.module.scss';

class PostSuggestionForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      suggestion: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handleChange(event) {
    const { name, value } = event.target

    this.setState({ [name]: value })
  }

  onSubmit(e) {
    e.preventDefault()

    if (this.state.suggestion === '') {
      alert("Can't be empty")
      return
    }

    document.getElementById('theFormID').submit();
  }


  render() {

    return (
      <form onSubmit={this.onSubmit} id="theFormID" name="suggestion" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" className={styles.PostSuggestionForm}>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="suggestion" />
        <i><small className={styles.Header}>
          Have a question? Have a suggestion for the next post?
        </small>
        </i>
        <div className={styles.Wrapper}>
          <input
            placeholder="Submit anonymously"
            name="suggestion"
            type="text"
            onChange={this.handleChange}
          />
          <button type="submit">Submit</button>
        </div>
      </form >
    )
  }

}

export default PostSuggestionForm;