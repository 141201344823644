// @flow
import React from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import ShareButtons from '../ShareButtons';
import type { Node } from '../../types';

type Props = {
  post: Node,
};

const Post = ({ post, footerLinksData }: Props) => {
  const { html } = post;
  const { tagSlugs, slug, readingTime } = post.fields;
  const { tags, title, date } = post.frontmatter;
  const url = `https://ronatory.com${slug}`;

  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">
        All Posts
      </Link>

      <div className={styles['post__content']}>
        <Content body={html} title={title} readingTime={readingTime.text} />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} />

        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}

        <ShareButtons
          twitterHandle="ronatory"
          url={url}
          title={title}
          tags={tags}
        />

        <br />

        {/* left is back/past, right is forth/future */}
        <div className={styles['post__footer__links']}>
          {/* double ampersand does checks the thruthyness value of footerLinksData.prev and footerLinksData.next */}
          {footerLinksData.prev && (
            <Link to={footerLinksData.prev.fields.slug}>
              ← {footerLinksData.prev.frontmatter.title}
            </Link>
          )}
          {footerLinksData.next && (
            <Link to={footerLinksData.next.fields.slug}>
              {footerLinksData.next.frontmatter.title} →
            </Link>
          )}
        </div>
        <Author />
      </div>
    </div>
  );
};

export default Post;
